import axios from 'axios';
// import router from '../routerManuaConfig'
import router from '../router/index'
import store from "../store";
import Vue from 'vue';

let base = '';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
//let base = process.env.NODE_ENV=="production"? 'http://api.training.wanghuolou.cn:82':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:5001':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:1004':'';

// 请求延时
axios.defaults.timeout = 60000

var storeTemp = store;
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))
        // if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
        if (storeTemp.state.token) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + storeTemp.state.token;
        }
        saveRefreshtime();
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.status == 401) {
            var curTime = new Date()
            var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
            // 在用户操作的活跃期内
            if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                return refreshToken({token: window.localStorage.Token}).then((res) => {
                    if (res.success) {
                        store.commit("saveToken", res.response.token);
                        var curTime = new Date();
                        var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expiresIn));
                        store.commit("saveTokenExpire", expiredate);
                        error.config.__isRetryRequest = true;
                        error.config.headers.Authorization = 'Bearer ' + res.response.token;
                        return axios(error.config);
                    } else {
                        ToLogin()
                        return false;
                    }
                })
                    .catch((err) => {
                        ToLogin()
                        return false;
                    })
            } else {
                ToLogin()
                return false;
            }
        } else if (response.data.status == 403) {
            Vue.prototype.$message({
                message: '失败！该操作无权限',
                type: 'error'
            });
            return null;
        } else if (response.data.status == 429) {
            Vue.prototype.$message({
                message: '刷新次数过多，请稍事休息重试！',
                type: 'error'
            });
            return null;
        } else if (response.data.status == 500) {
            setTimeout(function () {
                Vue.prototype.$message({
                    message: '服务器错误，请稍后重试！',
                    type: 'error'
                });
            }, 1000)
            return null;
        }
        return response;
    },
    error => {
        // 超时请求处理
        var originalRequest = error.config;
        console.log(error.config)
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
            Vue.prototype.$message({
                message: '请求超时！',
                type: 'error'
            });
            originalRequest._retry = true
            return null;
        }
        if (error.response) {
            if (error.response.status == 401) {
                var curTime = new Date()
                var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
                // 在用户操作的活跃期内
                if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                    return refreshToken({token: window.localStorage.Token}).then((res) => {
                        if (res.success) {
                            // Vue.prototype.$message({
                            //     message: 'refreshToken success! loading data...',
                            //     type: 'success'
                            // });
                            store.commit("saveToken", res.response.token);
                            var curTime = new Date();
                            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expiresIn));
                            store.commit("saveTokenExpire", expiredate);
                            error.config.__isRetryRequest = true;
                            error.config.headers.Authorization = 'Bearer ' + res.response.token;
                            return axios(error.config);
                        } else {
                            // 刷新token失败 清除token信息并跳转到登录页面
                            ToLogin()
                            return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                        }
                    });
                } else {
                    // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                    ToLogin()
                    return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                }
            }
            // 403 无权限
            if (error.response.status == 403) {
                Vue.prototype.$message({
                    message: '失败！该操作无权限',
                    type: 'error'
                });
                return null;
            }
            // 429 ip限流
            if (error.response.status == 429) {
                Vue.prototype.$message({
                    message: '刷新次数过多，请稍事休息重试！',
                    type: 'error'
                });
                return null;
            }
            // 500
            if (error.response.status == 500) {
                setTimeout(function () {
                    Vue.prototype.$message({
                        message: '服务器错误，请稍后重试！',
                        type: 'error'
                    });
                }, 1000)
                return null;
            }
        }
        return ""; // 返回接口返回的错误信息
    }
);
const ToLogin = params => {
    store.commit("saveToken", "");
    store.commit("saveTokenExpire", "");
    store.commit("saveTagsData", "");
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('NavigationBar');
    var routerGo = router.currentRoute.fullPath.substring(0, 6)
    if (routerGo == "/login") {
    } else {
        router.replace({
            path: "/login",
            query: {redirect: router.currentRoute.fullPath}
        });
    }
    // router.replace({
    //     path: "/login",
    //     query: {redirect: router.currentRoute.fullPath}
    // });
    // 调试登录过期后重新登录第一次无法跳转到相应的页面，而是停留在登录页面，所以暂时注释，怀疑重新加载所以看不到console.log("redirect_api--") date:2020-12-23
    window.location.reload()
    return false;  //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
};
export const BaseApiUrl = base;
export const saveRefreshtime = params => {
    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))
    let refreshCount = 1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime = nowtime > expiretime ? nowtime : expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    } else {
        window.localStorage.refreshtime = new Date(-1);
    }
};

// 系统登录
export const requestLogin = params => {
    return axios.post(`${base}/omswebapi/login/login`, params).then(res => res.data);
};

// 获取登录短信验证码
export const getLoginCode = params => {
    return axios.get(`${base}/omswebapi/login/getLoginCode`, {params: params}).then(res => res.data);
};

// 短信验证码登录
export const codelogin = params => {
    return axios.get(`${base}/omswebapi/login/codelogin`, {params: params}).then(res => res.data);
};

//刷新token
export const refreshToken = params => {
    return axios.get(`${base}/omswebapi/login/refreshToken`, {params: params}).then(res => res.data);
};


//系统管理
//用户管理
export const getUserListPage = params => {
    return axios.get(`${base}/omswebapi/userInfo/getUserInfoListPage`, {params: params});
};

// 根据token获取用户详细信息
export const getUserByToken = params => {
    return axios.get(`${base}/omswebapi/userInfo/getUserInfoByToken`, {params: params}).then(res => res.data);
};

//新增用户信息
export const addUserInfo = params => {
    return axios.post(`${base}/omswebapi/userInfo/addUserInfo`, params);
};

//更新用户信息
export const updateUserInfo = params => {
    return axios.post(`${base}/omswebapi/userInfo/updateUserInfo`, params);
};

//删除用户信息
export const deleteUserInfo = params => {
    return axios.delete(`${base}/omswebapi/userInfo/deleteUserInfo`, {params});
};

//发送修改密码手机短信验证码
export const sendModifyPasswordSMSVerificationCode = params => {
    return axios.post(`${base}/omswebapi/SystemManager/SendModifyPasswordSMSVerificationCode`, params);
};

//修改用户登录密码
export const modifyUserPassword = params => {
    return axios.post(`${base}/omswebapi/userInfo/modifyUserPassword`, params);
};

//系统管理
//菜单权限管理

//根据用户ID获取路由树
export const getNavigationBar = params => {
    return axios.get(`${base}/omswebapi/menuPermissions/getNavigationBar`, {params: params}).then(res => res.data);
};

//获取菜单树
export const getMenuTree = params => {
    return axios.get(`${base}/omswebapi/menuPermissions/getMenuTree`, {params: params});
};

//获取菜单权限树形列表
export const getMenuPermissionsTree = params => {
    return axios.get(`${base}/omswebapi/menuPermissions/getMenuPermissionsTree`, {params: params});
};

//新增菜单权限信息
export const addMenuPermissionsInfo = params => {
    return axios.post(`${base}/omswebapi/menuPermissions/addMenuPermissionsInfo`, params);
};

//更新菜单权限信息
export const updateMenuPermissionsInfo = params => {
    return axios.post(`${base}/omswebapi/menuPermissions/updateMenuPermissionsInfo`, params);
};

//删除菜单权限信息
export const deleteMenuPermissionsInfo = params => {
    return axios.delete(`${base}/omswebapi/menuPermissions/deleteMenuPermissionsInfo`, {params: params});
};

//保存菜单权限分配
export const assignMenuPermissions = params => {
    return axios.post(`${base}/omswebapi/menuPermissions/assignMenuPermissions`, params);
};

//通过角色获取菜单【无权限】
export const GetAssignMenuPermissionIdByRoleId = params => {
    return axios.get(`${base}/omswebapi/menuPermissions/getAssignMenuPermissionIdByRoleId`, {params: params});
};

//系统管理
//角色管理

//根据组织结构ID获取角色信息集合
export const getRoleInfosByOrganizationInfoID = params => {
    return axios.get(`${base}/omswebapi/roleInfo/getRoleInfoByName`, {params: params});
};

//获取角色信息分页列表
export const getRoleListPage = params => {
    return axios.get(`${base}/omswebapi/roleInfo/getRoleInfoListPage`, {params: params});
};

//新增角色信息
export const addRoleInfo = params => {
    return axios.post(`${base}/omswebapi/roleInfo/addRoleInfo`, params);
};

//更新角色信息
export const updateRoleInfo = params => {
    return axios.post(`${base}/omswebapi/roleInfo/updateRoleInfo`, params);
};

//删除角色信息
export const deleteRoleInfo = params => {
    return axios.delete(`${base}/omswebapi/roleInfo/deleteRoleInfo`, {params: params});
};

//系统管理
// 部门管理

//获取组织结构树
export const getOrganizationTree = params => {
    return axios.get(`${base}/omswebapi/departmentInfo/getDepartmentTree`, {params: params});
};

//新增组织结构信息
export const addOrganizationInfo = params => {
    return axios.post(`${base}/omswebapi/departmentInfo/addDepartmentInfo`, params);
};

//更新组织结构信息
export const updateOrganizationInfo = params => {
    return axios.post(`${base}/omswebapi/departmentInfo/updateDepartmentInfo`, params);
};

//删除组织结构信息
export const deleteOrganizationInfo = params => {
    return axios.delete(`${base}/omswebapi/departmentInfo/deleteDepartmentInfo`, {params: params});
};

//系统管理
// 接口管理

//获取接口信息树形列表
export const getApiModulesInfoTree = params => {
    return axios.get(`${base}/omswebapi/apiModulesInfo/getApiModulesInfoTree`, {params: params});
};

//获取接口菜单树
export const getApiMenuTree = params => {
    return axios.get(`${base}/omswebapi/apiModulesInfo/getApiMenuTree`, {params: params});
};

//新增接口信息
export const addApiModulesInfo = params => {
    return axios.post(`${base}/omswebapi/apiModulesInfo/addApiModulesInfo`, params);
};

//更新接口信息
export const updateApiModulesInfo = params => {
    return axios.post(`${base}/omswebapi/apiModulesInfo/updateApiModulesInfo`, params);
};

//删除接口信息
export const deleteApiModulesInfo = params => {
    return axios.delete(`${base}/omswebapi/apiModulesInfo/deleteApiModulesInfo`, {params: params});
};

//系统管理
// 数据权限管理

//获取全部数据权限信息列表
export const getAllDataPermissionsInfoList = params => {
    return axios.get(`${base}/omswebapi/dataPermissionsInfo/getAllDataPermissionsInfoList`, {params: params});
};

//新增数据权限信息
export const addDataPermissionsInfo = params => {
    return axios.post(`${base}/omswebapi/dataPermissionsInfo/addDataPermissionsInfo`, params);
};

//更新数据权限信息
export const updateDataPermissionsInfo = params => {
    return axios.post(`${base}/omswebapi/dataPermissionsInfo/updateDataPermissionsInfo`, params);
};

//删除数据权限信息
export const deleteDataPermissionsInfo = params => {
    return axios.delete(`${base}/omswebapi/dataPermissionsInfo/deleteDataPermissionsInfo`, {params});
};

//保存数据权限分配
export const assignDataPermissions = params => {
    return axios.post(`${base}/omswebapi/dataPermissionsInfo/assignDataPermissions`, params);
};

//通过角色ID获取数据权限菜单【无权限】
export const getAssignDataPermissionIdByRoleId = params => {
    return axios.get(`${base}/omswebapi/dataPermissionsInfo/getAssignDataPermissionIdByRoleId`, {params: params});
};

//通用设置
//审批流程管理

//获取审批流程信息分页列表
export const getApprovalProcessListPage = params => {
    return axios.get(`${base}/omswebapi/CommonSettings/GetApprovalProcessListPage`, {params: params});
};

//新增审批流程
export const addApprovalProcess = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/AddApprovalProcess`, params);
};

//更新审批流程
export const updateApprovalProcess = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateApprovalProcess`, params);
};

//根据审批流程ID 获取审批流程图
export const getApprovalProcessDiagramByApprovalProcessID = params => {
    return axios.get(`${base}/omswebapi/CommonSettings/GetApprovalProcessDiagramByApprovalProcessID`, {params: params});
};

//通用设置
//展示信息

//获取展示信息分页集合
export const getShowInformationListPage = params => {
    return axios.get(`${base}/omswebapi/CommonSettings/GetShowInformationListPage`, {params: params});
};

//新增展示信息
export const addShowInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/AddShowInformation`, params);
};

//更新展示信息
export const updateShowInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateShowInformation`, params);
};

//删除展示信息
export const deleteShowInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/DeleteShowInformation`, params);
};

//顶置/取消顶置展示信息
export const setTopShowInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/SetTopShowInformation`, params);
};

//通用设置
//轮播信息

//获取轮播信息分页集合
export const getCarouselInformationList = params => {
    return axios.get(`${base}/omswebapi/CommonSettings/GetCarouselInformationList`, {params: params});
};

//新增轮播信息
export const addCarouselInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/AddCarouselInformation`, params);
};

//更新轮播信息
export const updateCarouselInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateCarouselInformation`, params);
};

//删除轮播信息
export const deleteCarouselInformation = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/DeleteCarouselInformation`, params);
};
// 省市区
export const getAreaDictionaryListWithCode = params => {
    return axios.get(`${base}/omswebapi/areaDictionary/getAreaDictionaryListWithCode`, {params: params});
};

//通用设置
//数据字典信息

//获取数据字典信息列表
export const getDataDictionaryList = params => {
    return axios.get(`${base}/omswebapi/dataDictionary/getById`, {params: params});
};

//新增数据字典信息
export const addDataDictionary = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/AddDataDictionary`, params);
};

//更新数据字典信息
export const updateDataDictionary = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateDataDictionary`, params);
};

//删除数据字典信息
export const deleteDataDictionary = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/DeleteDataDictionary`, params);
};
// 根据命名空间获取数据字典
export const getByNamespace = params => {
    return axios.get(`${base}/omswebapi/dataDictionary/getByNamespace`, {params: params});
};

//通用设置
//行政区域
//获取行政区信息列表
export const getAreaDictionaryList = params => {
    return axios.get(`${base}/omswebapi/areaDictionary/getAreaDictionaryList`, {params: params});
};
//新增行政区信息
export const addAreaDictionary = params => {
    return axios.post(`${base}/omswebapi/aidrwebapi/CommonSettings/AddAreaDictionary`, params);
};

//删除行政区信息
export const deleteAreaDictionary = params => {
    return axios.post(`${base}/omswebapi/aidrwebapi/CommonSettings/DeleteAreaDictionary`, params);
};

//抓取行政区信息
export const grabAreaDictionary = params => {
    return axios.post(`${base}/omswebapi/aidrwebapi/CommonSettings/GrabAreaDictionary`, params);
};

// 新增客户端版本
export const addAppVersion = (req) => {
    return axios.post(`${base}/omswebapi/appVersion/addAppVersion`, req, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

// 删除客户端版本
export const deleteAppVersion = (params) => {
    return axios.delete(`${base}/omswebapi/appVersion/deleteAppVersion`, {params: params});
}

// 获取客户端版本分页列表
export const getAppVersionList = (params) => {
    return axios.get(`${base}/omswebapi/appVersion/getAppVersionList`, {params: params});
}

// 获取客户端版本分页列表
export const updateAppVersion = (params) => {
    return axios.post(`${base}/omswebapi/appVersion/updateAppVersion`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}
//鉴定点类型管理

//职业等级方向
export const getOproomProfessionDirList = (params) => {
    return axios.get(`${base}/omswebapi/oproomProfessionDir/getOproomProfessionDirList`, {params: params});
}


//考位类型管理
//获取当前数据列表
export const getExamSeatTypeList = (params) => {
    return axios.get(`${base}/omswebapi/opRoom/getExamSeatTypeList`, {params: params});
}

//考位设置
//获取当前数据列表
export const getExamSeatExtraDurationList = (params) => {
    return axios.get(`${base}/omswebapi/opRoom/getExamSeatExtraDurationList`, {params: params});
}
//根据条件获取鉴定点时间
export const getExtraDurationByCondition = (params) => {
    return axios.get(`${base}/omswebapi/opRoom/getExtraDurationByCondition`, {params: params});
}
//添加
export const addExamSeatExtraDuration = params => {
    return axios.post(`${base}/omswebapi/opRoom/addExamSeatExtraDuration`, params);
};
//编辑
export const updateExamSeatExtraDuration = params => {
    return axios.post(`${base}/omswebapi/opRoom/updateExamSeatExtraDuration`, params);
};

//
export const getWechatLoginUrl = (params) => {
    return axios.get(`${base}/omswebapi/login/getWechatLoginUrl`, {params: params});
}
// 修改站内通知记录已读状态
export const updateNotificationWebsiteIsRead = params => {
    return axios.post(`${base}/omswebapi/notificationWebsite/updateNotificationWebsiteIsRead`, params);
}
//更新行政区信息
export const updateAreaDictionary = params => {
    return axios.post(`${base}/omswebapi/CommonSettings/UpdateAreaDictionary`, params);
};
//工作流管理
//审批
export const approve = params => {
    return axios.post(`${base}/omswebapi/WorkFlow/Approve`, params);
};

//获取书籍
export const getBookList = (params) => {
    return axios.post(`${base}/omswebapi/book/getDataPage`, params);
}


//聊天记录
//获取消息记录列表
export const getMessageListPage = params => {
    return axios.post(`${base}/omswebapi/message/getDataPage`, params);
};

//获取问题反馈消息列表
export const getFeedbackDataPage = params => {
    return axios.post(`${base}/omswebapi/message/getFeedbackDataPage`, params);
};

//修改信息为反馈状态
export const addFeedback = params => {
    return axios.get(`${base}/omswebapi/message/addFeedback`, {params: params});
};

//根据姓名模糊查询学员
export const getCustomerList = params => {
    return axios.get(`${base}/omswebapi/message/getCustomerList`, {params: params});
};

//获取会话列表详情
export const getConversationDetail = params => {
    return axios.get(`${base}/omswebapi/message/getConversationDetail`, {params: params});
};

//处理反馈消息
export const handleFeedbackMessage = params => {
    return axios.get(`${base}/omswebapi/message/handleFeedbackMessage`, {params: params});
};

//查看知识点
export const getMessageKnowledgeList = params => {
    return axios.get(`${base}/omswebapi/message/getMessageKnowledgeList`, {params: params});
};

//根据题目id获取详情
export const getQuestionDetailsByQuestionId = params => {
    return axios.get(`${base}/omswebapi/message/getQuestionDetailsByQuestionId`, {params: params});
};

//导出列表导出excel
export const exportMessageExcel = params => {
    return axios.get(`${base}/omswebapi/message/exportMessageExcel`, {params: params});
};

//统计可以导出的数量
export const getMessageCountForExport = params => {
    return axios.get(`${base}/omswebapi/message/getMessageCountForExport`, {params: params});
};

//改消息状态为已查无反馈
export const checkMessageNoFeedback = params => {
    return axios.get(`${base}/omswebapi/message/checkMessageNoFeedback`, {params: params});
};

//修改问题反馈类型
export const changeCustomerFeedback = params => {
    return axios.get(`${base}/omswebapi/message/changeCustomerFeedback`, {params: params});
};

//知识库
//获取知识库列表
export const getKnowledgeDataPageList = params => {
    return axios.post(`${base}/omswebapi/knowledge/getDataPage`, params);
};

//新增知识库
export const addKnowledge = (params) => {
    return axios.post(`${base}/omswebapi/knowledge/add`, params);
}

export const updateKnowledge = (params) => {
    return axios.post(`${base}/omswebapi/knowledge/update`, params);
}

export const deleteKnowledge = (params) => {
    return axios.delete(`${base}/omswebapi/knowledge/delete`, {params: params});
}

export const viewKnowledge = (params) => {
    return axios.get(`${base}/omswebapi/knowledge/info`, {params: params});
}

export const associationBook = (params) => {
    return axios.post(`${base}/omswebapi/knowledge/associationBook`, params);
}

export const cancelAssociationBook = (params) => {
    return axios.post(`${base}/omswebapi/knowledge/cancelAssociationBook`, params);
}

export const changeBookStatus = (params) => {
    return axios.patch(`${base}/omswebapi/knowledge/changeBookStatus`, {}, {params: params});
}

//槽位管理
//获取槽位列表
export const getSlotListPage = params => {
    return axios.post(`${base}/omswebapi/slot/getDataPage`, params);
};

//删除槽位
export const deleteSlot = params => {
    return axios.get(`${base}/omswebapi/slot/delete`, {params: params});
};

//添加槽位
export const saveSlot = params => {
    return axios.post(`${base}/omswebapi/slot/add`, params);
};

//编辑槽位
export const editSlot = params => {
    return axios.post(`${base}/omswebapi/slot/edit`, params);
};

//模型管理配置
//获取模型配置
export const getModelConfigInfo = (params) => {
    return axios.get(`${base}/omswebapi/modelConfig/info`);
}

//保存模型配置
export const saveModelConfig = (params) => {
    return axios.post(`${base}/omswebapi/modelConfig/save`, params);
}

// 书籍管理
// 获取书籍列表
export const getBookDataPageList = params => {
    return axios.post(`${base}/omswebapi/book/getDataPage`, params);
};
// 获取书籍内容 
export const getBookContent = params => {
    return axios.get(`${base}/omswebapi/book/getBookContent`, { params: params });
};
// 添加知识点
export const addBookContent = params => {
    return axios.post(`${base}/omswebapi/book/addBookContent`, params);
};
// 编辑知识点
export const editBookContent = params => {
    return axios.post(`${base}/omswebapi/book/editBookContent`, params);
};

// 删除知识点
export const deleteBookContent = params => {
    return axios.get(`${base}/omswebapi/book/deleteBookContent`, { params: params });
};

// 书籍提交审核
export const commitBookForAudit = params => {
    return axios.get(`${base}/omswebapi/book/commitBookForAudit`, { params: params });
};

// 获取知识库
export const getKnowledgeListByBizType = params => {
    return axios.get(`${base}/omswebapi/book/getKnowledgeListByBizType`, { params: params });
};

//获取知识库名称清单
export const getKnowledgeNames = params => {
    return axios.post(`${base}/omswebapi/knowledge/getKnowledgeNames`, params);
};

// 添加书籍
export const addBook = params => {
    return axios.post(`${base}/omswebapi/book/add`, params);
};
// 编辑书籍
export const editBook = params => {
    return axios.post(`${base}/omswebapi/book/edit`, params);
};
// 删除书籍
export const deleteBook = params => {
    return axios.get(`${base}/omswebapi/book/delete`,{ params: params });
};

//训练集管理
export const getTrainList = params => {
    return axios.post(`${base}/omswebapi/train/getDataPage`, params);
};

//生成数据集
export const generateTrainSet = params => {
    return axios.get(`${base}/omswebapi/train/generateTrainSet`,{ params: params });
};

//开始训练
export const startTrain = params => {
    return axios.post(`${base}/omswebapi/train/startTrain`, params);
};

//发布
export const publishTrain = params => {
    return axios.get(`${base}/omswebapi/train/publishTrain`, { params: params });
};

// 删除训练集
export const deleteTrain = params => {
    return axios.get(`${base}/omswebapi/train/deleteTrain`,{ params: params });
};

//获取数据管理
export const getInformationList = params => {
    return axios.post(`${base}/omswebapi/data/getDataPage`, params);
};

//删除数据管理
export const deleteInformation = (params) => {
    return axios.delete(`${base}/omswebapi/data/delete`, {params: params});
}

//标记数据
export const makeInformation = (params) => {
    return axios.post(`${base}/omswebapi/data/make`, params);
}

// 书籍待审核内容
export const getBookContentForAudit = params => {
    return axios.get(`${base}/omswebapi/book/getBookContentForAudit`,{ params: params });
};

// 书籍待审核内容
export const bookAudit = params => {
    return axios.get(`${base}/omswebapi/book/bookAudit`,{ params: params });
};

// 获取书籍模版
export const getBookTemplate = params => {
    return axios.get(`${base}/omswebapi/book/getBookTemplate`,{ params: params });
};

// 重新解析书籍
export const reAnalyseBook = params => {
    return axios.get(`${base}/omswebapi/book/reAnalyseBook`,{ params: params });
};

//籍导入知识库
export const bookImportKnowledge = params => {
    return axios.post(`${base}/omswebapi/knowledge/bookImportKnowledge`, params);
};
//修改知识库文档内容启用状态
export const changeBookContentStatus = params => {
    return axios.patch(`${base}/omswebapi/knowledge/changeBookContentStatus`, {}, {params: params});
};
//知识库知识点详情
export const knowledgeBookContent = params => {
    return axios.get(`${base}/omswebapi/knowledge/knowledgeBookContent`, { params: params });
};

// 数据信息详情
export const getDataInfo = params => {
    return axios.get(`${base}/omswebapi/data/info`,{ params: params });
};
// 数据信息详情
export const assignMark = params => {
    return axios.post(`${base}/omswebapi/data/assignMark`,params);
};


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import mavonEditor from 'mavon-editor'
import "element-ui/lib/theme-chalk/index.css";
import 'font-awesome/css/font-awesome.min.css'
import '../util/globalStyle.css'
import './promissionRouter'//这里进行路由后台获取的模拟
Vue.use(ElementUI);
Vue.use(mavonEditor)
Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
